import React from "react"
import Container from "react-bootstrap/Container"
import {objectLang} from "../functions"
import Row from "react-bootstrap/Row"
import {PathLineClass} from "../elements/PathLineClass"

export class NoteViewCardClass extends React.Component {

    render() {

        const {auth, note, saveNoteHandler, closeNoteHandler} = {
            auth: this.props.auth,
            note: this.props.note,
            saveNoteHandler: this.props.saveNoteHandler,
            closeNoteHandler: this.props.closeNoteHandler
        }

        let lang = objectLang().language

        const availableScreenHeight = Math.trunc(window.screen.availHeight * 0.5)
        const styleTextarea = {
            height: availableScreenHeight,
            background: "beige"
        }

        return (
            <>
                {<PathLineClass documents={{path: note.path}}/>}
                <Container className={"mt-3 mt-lg-5 text-center"}>
                    <div className={"d-flex flex-wrap justify-content-center card mb-1"}>
                        <div className={"card-header card-note text-success d-flex justify-content-start"}>
                            <Row xs={2} className={"d-flex flex-nowrap justify-content-start w-100"}>
                                <div style={{width: "96%"}}>
                                    <input
                                        placeholder={lang.body.createNotePage.placeholderName}
                                        type={"text"}
                                        className={"form-control fs-2 text-primary text-center w-100"}
                                        id={"name"}
                                        style={{background: "beige"}}
                                        defaultValue={note.name}
                                    />
                                </div>
                                <button id={"button-close-note m-0"}
                                        type={"button"}
                                        className={"btn-close fs-2 mt-1"}
                                        aria-label={"Close"}
                                        onClick={closeNoteHandler}>
                                </button>
                            </Row>
                        </div>
                        <div className={"card-body card-note"}>
                        <textarea
                            placeholder={lang.body.createNotePage.placeholderText}
                            className={"form-control fs-4 text-success"}
                            id={"body-note"}
                            style={styleTextarea}
                            defaultValue={note.body}
                        />
                        </div>
                    </div>
                    <button
                        className={"col-4 col-md-3 btn btn-info border-0 m-1"}
                        type={"button"}
                        onClick={() => {
                            window.location.replace(localStorage.getItem('pathBack'))
                        }}>
                        {`${lang.body.general.btnBack}`}
                    </button>
                    <button
                        className={"col-4 col-md-3 btn btn-success border-0 m-1"}
                        type={"button"}
                        disabled={!auth.isAuthenticated}
                        onClick={saveNoteHandler}>
                        {lang.body.createNotePage.btnSaveNote}
                    </button>
                    <button className={"col-4 col-md-3 btn btn-info border-0 m-1"}
                            type={"button"}
                            disabled={!auth.isAuthenticated}
                            onClick={() => {
                                window.location.replace(`/note/detail/${note["_id"]}`)
                            }}>
                        {lang.body.createNotePage.btnPropertiesNote}
                    </button>
                </Container>
            </>
        )
    }
}