import React, {useCallback, useContext, useEffect, useState} from 'react'
import {AuthContext} from '../context/AuthContext'
import {NavLink} from "react-router-dom"
import {objectLang, setDataModalFromCatch, setErrorWindow} from "../functions"
import {createBrowserHistory} from "history"
import {useHttp} from "../hooks/http.hook"
import config from "../config.json"
import {ModalWin} from "../elements/ModalWin"
import {PathLineClass} from "../elements/PathLineClass"
import {LoaderClass} from "../elements/LoaderClass"

export const MainPage = () => {
    localStorage.setItem('page', 'MainPage')
    localStorage.setItem('modal', JSON.stringify(setErrorWindow('', 540)))
    const history = createBrowserHistory()
    localStorage.setItem('pathBack', history.location.pathname)
    const auth = useContext(AuthContext)
    const {request, loading} = useHttp()
    history.push('/')
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModalCatch, setDataModalCatch] = useState({})
    const [languages, setLanguages] = useState(objectLang().languages)

    const fetch = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/user/check`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.code === 6041) {
                window.location.reload()
                return
            }

            const arrLang = data.languages.language
            let lang = {}
            for (const key in arrLang) {
                lang[arrLang[key].lang] = arrLang[key]
            }
            setLanguages(lang)

        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }, [auth, request])

    useEffect(() => {
        fetch().then()
    }, [fetch])

    const currentLang = objectLang().language.lang
    if (JSON.stringify(objectLang().languages) !== JSON.stringify(languages)) {
        localStorage.setItem('languages', JSON.stringify(languages))
        localStorage.setItem('language', JSON.stringify(languages[currentLang]))
    }

    const language = objectLang().language
    const localStorageLang = language.lang
    const langNavbar = language.navbar
    let elementsNav = langNavbar.isNotAuth

    if (auth.accessToken) {
        elementsNav = langNavbar.isAuth // Кнопочки на главной странице что приходят из базы данных
        if (!localStorage.getItem('isChangedLang')) {
            if (auth.language !== localStorageLang) {
                for (const i in languages) {
                    if (i === auth.language) {
                        localStorage.setItem('language', JSON.stringify(languages[i]))
                    }
                }
                localStorage.setItem('isChangedLang', 'true')
                window.location.reload()
            }
        }
    }

    // Исключения из кнопок на главной странице
    const links = []

    for (const i in elementsNav) {
        const name = elementsNav[i]
        if (i === 'title'
            || i === 'placeholderSearch'
            || i === 'btnSearch'
            || i === 'btnLinks'
            || i === 'btnNotes'
            || i === 'btnCreate'
            || i === 'btnShared'
            || i === 'btnFolders'
        ) {
            continue
        }

        if (i === 'btnPublicLinks' && currentLang !== 'ru') {
            continue
        }

        if (i.includes('btn')) {
            const link = i.replace('btn', 'link')
            links.push({name: name, link: elementsNav[link], className: i.replace('btn', '')})
        }

    }
    const arrLinks = Object.keys(links)

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowedCatch) {
        return (
            <ModalWin data={dataModalCatch}/>
        )
    }

    return (
        <>
            {<PathLineClass/>}
            <div className={"d-flex justify-content-center flex-wrap"}>
                {arrLinks.map((link, index) => {
                        links[link].className === 'Out' ? links[link].bodyClass = 'exit' : links[link].bodyClass = ''
                        return (
                            <NavLink
                                to={`${links[link].link}`}
                                className={`card text-decoration-none m-3 col-lg-3 col-sm-4 col-sm-1 ${links[link].className}`}
                                key={index.toString()}>
                                {
                                    <div className={"m-1 w-auto"}>
                                        <div className={`card border-primary p-3 text-center ${links[link].bodyClass}`}>
                                            <div className={"card-body text-success pt-1 ps-2 pe-2 pb-0"}>
                                                <h5 className={"card-title title-card"}><strong
                                                    id={'main-page-button-' + index.toString()}>{links[link].name}</strong>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </NavLink>
                        )
                    }
                )}
            </div>
            {!auth.isAuthenticated && <div className={'container mt-4'}>
                {/*<h2 className={'text-success text-center mt-2 mb-2'}>Приветствие</h2>*/}
                <h5 className={'text-success text-start text-wrap ms-md-2 ps-3 pe-3 fs-5'}>
                    {language.body.welcome}
                </h5>
            </div>}
        </>
    )
}
