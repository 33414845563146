import React, {useCallback, useContext, useEffect, useState} from 'react'
import {AuthContext} from "../context/AuthContext"
import config from "../config.json"
import {useHttp} from "../hooks/http.hook"
import {useParams} from "react-router-dom"
import {NoteViewCardClass} from "../components/NoteViewCardClass"
import {setDataModalFromCatch, setErrorWindow} from "../functions"
import {ModalWin} from "../elements/ModalWin"
import {LoaderClass} from "../elements/LoaderClass"

export const NotePage = () => {
    localStorage.setItem('page', 'NotePage')
    const auth = useContext(AuthContext)
    const {request, loading} = useHttp()
    const [note, setNote] = useState({})
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModalCatch, setDataModalCatch] = useState({})
    const noteId = useParams().id

    const getNote = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/note/view/${noteId}`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })
            if (data.status > 200) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            } else {
                setNote(data.note)
            }

        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }, [auth, request, noteId])

    useEffect(() => {
        getNote().then()
    }, [getNote])

    if (auth.isAuthenticated) {
        localStorage.setItem('pathBack', `/folder/open/${note.parentFolder}`)
    }
    if (note.owner === '64648579d6767031d41bd359') {
        localStorage.setItem('pathBack', `/folder/open-public/${note.parentFolder}`)
    }

    const saveNoteHandler = async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/note/save/${note["_id"]}`,
                'POST',
                {
                    userId: auth.userId,
                    name: document.querySelector('#name').value,
                    body: document.querySelector('#body-note').value,
                    parentFolder: note.parentFolder
                },
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })
            if (data.status > 200) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            } else {
                window.location.reload()
            }

        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }

    const closeNoteHandler = () => {
        window.location.replace(localStorage.getItem('pathBack'))
    }

    if (loading) {
        return <LoaderClass />
    }

    if (!allowed) {
        return (
            <>
                <ModalWin data={dataModal}/>
            </>

        )
    }

    if (!allowedCatch) {
        return (
            <ModalWin data={dataModalCatch}/>
        )
    }

    return (
        <>
            {<NoteViewCardClass
                auth={auth}
                note={note}
                saveNoteHandler={saveNoteHandler}
                closeNoteHandler={closeNoteHandler}
            />}
        </>
    )
}