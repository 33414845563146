import React, {useState} from 'react'
import {useHttp} from '../hooks/http.hook'
import {objectLang, setDataModalFromCatch, setErrorWindow, validationEmail} from "../functions"
import config from '../config.json'
import {ModalWin} from "../elements/ModalWin"
import Button from "react-bootstrap/Button"
import {LoaderClass} from "../elements/LoaderClass"

export const RestorePage = () => {
    const {loading, request} = useHttp()
    const [form, setForm] = useState({email: ''})
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModalCatch, setDataModalCatch] = useState({})

    const changeHandler = event => {
        const name = event.target.name
        const value = event.target.value
        setForm({...form, [name]: value})
    }

    const restoreHandler = async () => {
        try {
            let host
            window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
            const lang = objectLang().language
            const validEmail = validationEmail(form.email)
            if (!validEmail.flag) {
                setAllowed(false)
                setDataModal(setErrorWindow(
                    '',
                    0,
                    lang.messages[`code_${validEmail.code}`],
                    lang.modal.errors.btnTryAgain,
                    '',
                    '',
                    'replaceTo /main'
                ))
                return
            }
            const encodedEmail = btoa(form.email)
            const data = await request(
                `${host}/api/user/restore`,
                'POST',
                {
                    email: encodedEmail
                })

            if ((data.status > 200)) {
                setAllowed(false)
                setDataModal(setErrorWindow(
                    '',
                    data.code,
                    lang.messages[`code_${data.code}`],
                    lang.modal.errors.btnTryAgain,
                    'replaceTo /restore',
                    '',
                    'replaceTo /main'
                ))
                // return
            }

            if ((data.code === 614)) {
                setAllowed(false)
                setDataModal(setErrorWindow(
                    lang.modal.warnings.title,
                    data.code,
                    lang.messages[`code_${data.code}`],
                    lang.modal.buttons.btnOK,
                    'replaceToMain',
                    lang.modal.buttons.btnCancel
                ))
                // return
            }
        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }

    if (loading) {
        return <LoaderClass />
    }
    if (!allowed) {
        return (
            <>
                <ModalWin data={dataModal}/>
            </>
        )
    }

    if (!allowedCatch) {
        return (
            <ModalWin data={dataModalCatch}/>
        )
    }

    const lang = objectLang().language

    return (
        <>
            <div className={'container col-sm-9 col-xl-6 col-xxl-4'}>
                <form className={'rounded'}>
                    <fieldset className={'teal m-3 p-3'}>
                        <legend id={"legend"} className={'fs-1'}>{lang.body.restorePage.title}</legend>
                        <div id={"divEmail"} className={"mb-3"}>
                            <label
                                htmlFor={"email"}
                                className={"form-label"}>
                                {lang.body.restorePage.labelEmail}
                            </label>
                            <input
                                type={"text"}
                                name={"email"}
                                id={"email"}
                                value={form.email}
                                onChange={changeHandler}
                                className={"form-control"}
                                placeholder={lang.body.restorePage.placeholderEmail}
                            />
                        </div>

                        <div className={"container d-flex justify-content-center"}>
                            <div className={"row"}>
                                <div className={"col-sm"}>
                                    <Button
                                        id={"restore"}
                                        variant={"primary"}
                                        type={'button'}
                                        onClick={restoreHandler}
                                        disabled={loading}
                                        className={"mt-2 btn btn-primary"}>
                                        {lang.body.restorePage.btnSend}
                                    </Button>
                                    <Button
                                        variant={"secondary"}
                                        className={"mt-2 ms-2 btn btn-primary"}
                                        onClick={() => {
                                            window.location.replace('/auth')
                                        }}>
                                        {lang.modal.buttons.btnToCancel}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </>
    )
}
