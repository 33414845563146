import React from 'react'
import {getIcon, objectLang} from "../functions"
import Modal from "react-bootstrap/Modal"
import {Link} from "react-router-dom"
import folderImg from "../images/full-folder.png"
import linkImg from "../images/link-logo-search.jpg"
import noteImg from "../images/note.png"
import {PathLineClass} from "../elements/PathLineClass"
import {LoaderClass} from "../elements/LoaderClass"

export class ViewResultsSearchClass extends React.Component {

    render() {

        const {data} = {
            data: this.props.data
        }

        const folders = data.body.folders
        const links = data.body.links
        const notes = data.body.notes
        const requestSearch = data.requestSearch
        const countFolders = folders.length
        const countLinks = links.length
        const countNotes = notes.length

        const lang = objectLang().language

        let imgLink = linkImg

        if ((!countFolders && !countLinks && !countNotes)) {
            return (
                <Modal.Body className={'founded-items'}>
                    <h5 className={'text-center text-success'}>
                        {data.title}
                        {data.loading && <LoaderClass/>}
                    </h5>
                </Modal.Body>
            )
        }

        const closeWindowSearchHandler = () => {
            const fade = document.querySelector(".modal-backdrop")
            const modal = document.querySelector(".modal")
            fade.remove()
            modal.remove()
        }

        return (
            <Modal.Body className={'founded-items'}>
                <div>
                    {data.loading && <LoaderClass/>}
                    <h5 className={'text-center text-success'}>
                        {lang.messages.code_703}
                    </h5>
                    <h6 className={'text-center text-success'}>
                        {`${lang.messages.code_704}`}<strong
                        className={'text-decoration-underline'}>{`${countFolders}`}</strong>&nbsp;
                        {`${lang.messages.code_705}`}<strong
                        className={'text-decoration-underline'}>{`${countLinks}`}</strong>&nbsp;
                        {`${lang.messages.code_706}`}<strong
                        className={'text-decoration-underline'}>{`${countNotes}`}</strong>
                    </h6>
                </div>

                {/* Массив папок */}
                {folders && folders.map((item, i) => {
                    const key = `${i}-F-Search`
                    const folder = item
                    const pathFolder = folder.path
                    pathFolder.pop()
                    const documents = {path: pathFolder}
                    return (
                        <div key={key} className={'bg-light rounded-1 border mt-2'}>
                            {<PathLineClass documents={documents} search={true}/>}
                            <div className={"gap-2"}>
                                <Link to={`/folder/open/${folder.parentFolder}?filter=${requestSearch}`}
                                      className={'folder-search text-decoration-none'}
                                    // target={"_blank"}
                                      rel={"noreferrer noopener"}
                                      onClick={closeWindowSearchHandler}>
                                    <button className={"w-100 item-search d-flex flex-nowrap btn btn-light rounded-0"}
                                            type={'button'}
                                            onContextMenu={(e) => {
                                                // contextMenuItem(e, key)
                                            }}>
                                        <img id={"img-folder"} src={folderImg}
                                             className={"img-search rounded-4"}
                                             alt={"..."}/>

                                        <div className={'name-folder-search w-75 mt-2 pt-2'}>
                                            <p className={"fs-5 name-link text-start ms-3 mb-2 text-black p-0 text-nowrap"}>{folder.name}</p>
                                        </div>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    )
                })}

                {/* Массив ссылок */}
                {links && links.map((item, i) => {
                    const key = `${i}-L-Search`
                    const link = item
                    imgLink = link["thumb_url"]
                    // console.log(link)
                    if (!imgLink) {
                        imgLink = getIcon(link.from, linkImg)
                    }
                    link.parentFolder = item.parentFolder
                    const documents = {path: link.path}
                    return (
                        <div key={key} className={'bg-light rounded-1 border mt-2'}>
                            {<PathLineClass documents={documents} search={true}/>}
                            <div className={"gap-2"}>
                                <Link to={`/folder/open/${link.parentFolder}?filter=${requestSearch}`}
                                      className={'link-search text-decoration-none'}
                                    // target={"_blank"}
                                      rel={"noreferrer noopener"}>
                                    <button
                                        className={"w-100 item-search d-flex flex-nowrap btn btn-light rounded-0 overflow-hidden"}
                                        type={'button'}
                                        onContextMenu={(e) => {
                                            // contextMenuItem(e, key)
                                        }}
                                        onClick={closeWindowSearchHandler}>
                                        <img id={"img-link"} src={imgLink}
                                             className={"img-search rounded-4"}
                                             alt={"..."}/>
                                        <div className={'name-link-search mt-2 pt-2'}>
                                            <p className={"fs-5 name-link text-start ms-3 mb-2 text-black p-0 text-nowrap"}>{link.name}</p>
                                        </div>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    )
                })}

                {/* Массив записок */}
                {notes && notes.map((item, i) => {
                    const key = `${i}-N-Search`
                    const note = item
                    const documents = {path: note.path}
                    return (
                        <div key={key} className={'bg-light rounded-1 border mt-2'}>
                            {<PathLineClass documents={documents} search={true}/>}
                            <div className={"gap-2"}>
                                <Link to={`/folder/open/${note.parentFolder}?filter=${requestSearch}`}
                                      className={'note-search text-decoration-none'}
                                    // target={"_blank"}
                                      rel={"noreferrer noopener"}
                                      onClick={closeWindowSearchHandler}>
                                    <button className={"w-100 item-search d-flex flex-nowrap btn btn-light rounded-0"}
                                            type={'button'}
                                            onContextMenu={(e) => {
                                                // contextMenuItem(e, key)
                                            }}>
                                        <img id={"img-note"} src={noteImg}
                                             className={"img-search rounded-4"}
                                             alt={"..."}/>
                                        <div className={'name-note-search mt-2 pt-2'}>
                                            <p className={"fs-5 name-note text-start ms-3 mb-2 text-black p-0 text-nowrap"}>{note.name}</p>
                                        </div>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    )
                })}
            </Modal.Body>
        )
    }
}