import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import config from '../config.json'
import {
    objectLang,
    setDataModalFromCatch,
    setErrorWindow
} from "../functions"
import {ModalWin} from "../elements/ModalWin"
import {LoaderClass} from "../elements/LoaderClass"
import {NoteCardClass} from "../components/NoteCardClass"
import {PathLineClass} from "../elements/PathLineClass"

export const NoteDetailPage = () => {
    localStorage.setItem('page', 'NoteDetailPage')

    let lang = objectLang().language
    const auth = useContext(AuthContext)
    const {request, loading} = useHttp()
    const [note, setNote] = useState({})
    const [owner, setOwner] = useState({})
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModalCatch, setDataModalCatch] = useState({})
    const noteId = useParams().id

    const getNote = useCallback(async () => {

        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/note/detail/${noteId}`,
                'GET',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })
            if (data.status > 200) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            }

            setNote(data["note"])
            setOwner(data["owner"])
        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }, [auth, noteId, request])

    useEffect(() => {
        getNote().then()
    }, [getNote])

    const removeNoteHandler = () => {
        setAllowed(false)
        setDataModal({
            title: lang.modal.warnings.title,
            desc: lang.modal.warnings.desc.deleteNote,
            btnCancel: lang.modal.warnings.btnCancel,
            btnOK: lang.modal.warnings.btnOK,
            clickOK: `replaceTo /note/remove/${note["_id"]}`,
            clickCancel: 'reload'
        })
    }

    if (loading) {
        return <LoaderClass/>
    }

    if (!allowed) {
        return <ModalWin data={dataModal}/>
    }

    if (!allowedCatch) {
        return <ModalWin data={dataModalCatch}/>
    }

    return (
        <>
            {<PathLineClass documents={{path: note.path}}/>}
            <div className={'container'}>
                {<NoteCardClass
                    note={note}
                    owner={owner}
                    removeNoteHandler={removeNoteHandler}
                />}
            </div>
        </>

    )
}
