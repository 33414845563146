import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useHttp} from '../hooks/http.hook'
import {AuthContext} from '../context/AuthContext'
import config from '../config.json'
import {setDataModalFromCatch, setErrorWindow} from "../functions"
import {ModalWin} from "./ModalWin"
import {LoaderClass} from "./LoaderClass"

export const DeletePage = (string) => {

    const auth = useContext(AuthContext)
    const [allowed, setAllowed] = useState(true)
    const [dataModal, setDataModal] = useState({})
    const [allowedCatch, setAllowedCatch] = useState(true)
    const [dataModalCatch, setDataModalCatch] = useState({})
    const {request, loading} = useHttp()
    const id = useParams().id

    const getData = useCallback(async () => {
        let host
        window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
        try {
            const data = await request(
                `${host}/api/${string.string}/remove/${id}`,
                'DELETE',
                null,
                {
                    Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`
                })

            if (data.status > 200) {
                setAllowed(false)
                setDataModal(setErrorWindow('', data.code))
            } else {
                // alert(data.status + ' ' + data.message + ' ' + data.code)
                window.location.replace(localStorage.getItem('pathBack'))
            }

        } catch (e) {
            setAllowedCatch(false)
            setDataModalCatch(setDataModalFromCatch(e))
        }
    }, [auth, id, request, string])

    useEffect(() => {
        getData().then()
    }, [getData])

    if (loading) {
        return <LoaderClass />
    }

    if (!allowed) {
        return (
            <>
                <ModalWin data={dataModal}/>
            </>

        )
    }

    if (!allowedCatch) {
        return (
            <ModalWin data={dataModalCatch}/>
        )
    }
}
