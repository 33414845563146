import React from "react"
import {Link} from "react-router-dom"
import {
    changeHandler,
    formatDate,
    objectLang
} from "../functions"

export class FolderCardClass extends React.Component {

    render() {

        const {folder, owner, updateFolderHandler, removeFolderHandler, saveFolderHandler, closeHandler} = {
            folder: this.props.folder,
            owner: this.props.owner,
            updateFolderHandler: this.props.updateFolderHandler,
            removeFolderHandler: this.props.removeFolderHandler,
            saveFolderHandler: this.props.saveFolderHandler,
            closeHandler: this.props.closeHandler,
        }

        let lang = objectLang().language
        let formDetailName, formDetailDescription
        folder.name ? formDetailName = 'form-detail' : formDetailName = ''
        folder.description ? formDetailDescription = 'form-detail' : formDetailDescription = ''

        return (
            <>
                <div className={'container col col-sm-12 col-xl-6'}>
                    <form className={'rounded form-link-detail mt-2'}>
                        <div className={"mb-5 ms-2 me-1 mt-3 d-flex justify-content-between"}>
                            <div className={"d-flex justify-content-center w-100"}>
                                <Link to={`${`/folder/open/${folder["_id"]}`}`}
                                      className={"btn btn-success w-100 ms-2 me-1 fs-3 p-0"}>
                                    {folder.name}
                                </Link>
                            </div>
                            <button id={"button-close-link"}
                                    type={"button"}
                                    className={"btn-close fs-2 p-2 m-0 me-1"}
                                    aria-label={"Close"}
                                    onClick={closeHandler}>
                            </button>
                        </div>
                        <p
                            id={"p-date-created"}
                            className={"mt-3 ms-3 mb-1"}>
                            {`${lang.body.linkPage.createdDate}`}&nbsp;
                            <strong>{formatDate(folder["dateCreated"]).date}</strong>
                        </p>

                        <fieldset className={'teal m-3 p-3'}>

                            <div id={"divFrom"} className={"mb-1"}>
                                <label
                                    id={"owner-label"}
                                    htmlFor={"owner"}
                                    className={"form-label"}>
                                    {"Владелец папки"}
                                </label>
                                <h4
                                    id={"owner"}
                                    className={`mb-3 ms-3`}
                                    placeholder={`${lang.body.linkPage.placeholderSiteLink}`}>
                                    {`${owner.name}`}
                                </h4>
                            </div>

                            <div id={"Name"} className={"mb-3"}>
                                <label
                                    id={"label-name"}
                                    htmlFor={"name"}
                                    className={"form-label"}>
                                    {`${lang.body.folderPage.labelName}`}
                                </label>
                                <input
                                    disabled
                                    type={"text"}
                                    name={"name"}
                                    id={"name"}
                                    defaultValue={`${folder.name}`}
                                    onChange={changeHandler}
                                    className={`${formDetailName} form-control`}
                                    placeholder={`${lang.body.folderPage.placeholderName}`}
                                />
                            </div>
                            <div id={"divDescription"} className={"mb-3"}>
                                <label
                                    id={"label-description"}
                                    htmlFor={"description"}
                                    className={"form-label"}>
                                    {`${lang.body.folderPage.labelDescription}`}
                                </label>
                                <textarea
                                    disabled
                                    name={"description"}
                                    id={"description"}
                                    defaultValue={folder.description}
                                    onChange={changeHandler}
                                    className={`${formDetailDescription} form-control`}
                                    placeholder={`${lang.body.folderPage.placeholderDescription}`}
                                />
                            </div>
                            <div className={"ms-1 me-1 row row-cols-xl-5 gap-xl-2 justify-content-center"}>
                                <div id={"btn-back"}
                                     className={"form-detail-btn btn btn-info mt-2 d-flex justify-content-center"}
                                     onClick={() => {
                                         window.location.replace(localStorage.getItem('pathBack'))
                                     }}>
                                    {`${lang.body.folderPage.buttonBack}`}
                                </div>
                                <div id={"btn-save"}
                                     className={"form-detail-btn btn btn-success disabled mt-2 d-flex justify-content-center"}
                                     onClick={saveFolderHandler}>
                                    {`${lang.body.folderPage.buttonSave}`}
                                </div>
                                <div id={"btn-update"}
                                     className={"form-detail-btn btn btn-warning mt-2 d-flex justify-content-center"}
                                     onClick={updateFolderHandler}>
                                    {`${lang.body.folderPage.buttonUpdate}`}
                                </div>
                                <div id={"btn-delete"}
                                     className={"form-detail-btn btn btn-danger mt-2 d-flex justify-content-center"}
                                     onClick={removeFolderHandler}>
                                    {`${lang.body.folderPage.buttonDelete}`}
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </>
        )
    }
}