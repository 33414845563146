import config from "./config.json"
import {createBrowserHistory} from "history"

const $ = require("jquery")

export const setErrorWindow = (title, code, desc, btnOK, clickOK, btnCancel, clickCancel) => {

    const lang = objectLang().language

    let pathBack = localStorage.getItem('pathBack')
    if (!pathBack) {
        pathBack = '/main'
    }
    if (!desc && !code) {
        desc = lang.messages[`code_${999}`]
    }
    if (!desc && code) {
        desc = lang.messages[`code_${code}`]
    }
    if (!btnOK) {
        btnOK = lang.modal.errors.btnOK
    }
    if (!btnCancel) {
        btnCancel = lang.modal.errors.btnCancel
    }
    if (!clickOK) {
        clickOK = 'reload'
    }
    if (!clickCancel) {
        clickCancel = `replaceTo ${pathBack}`
    }
    if (!title) {
        title = lang.modal.errors.title
    }
    if (clickOK === 'replaceToBack') {
        btnOK = lang.modal.buttons.btnToBack
    }
    if (clickOK === 'replaceToMain') {
        btnOK = lang.modal.buttons.btnToMain
    }
    if (clickOK === 'replaceToAuth') {
        btnOK = lang.modal.buttons.btnToAuth
    }

    let dataModal = {
        title: title,
        desc: desc,
        btnCancel: btnCancel,
        btnOK: btnOK,
        clickOK: clickOK,
        clickCancel: clickCancel
    }
    if (code === 0 || code === 604 || code === 613 || code === 614 || code === 615 || code === 6014) {
        localStorage.setItem('dataModal', JSON.stringify(dataModal))
        return dataModal
    }
    if (code === 6042) {
        dataModal.title = lang.modal.errors.title
        dataModal.desc = lang.messages.code_6042
        dataModal.btnCancel = lang.modal.errors.btnCancel
        dataModal.btnOK = lang.modal.errors.btnAuth
        dataModal.clickOK = 'replaceToAuthAndDeleteCookies'
        dataModal.clickCancel = 'replaceToMainAndDeleteCookies'
        localStorage.setItem('dataModal', JSON.stringify(dataModal))
        return dataModal
    }
    localStorage.setItem('dataModal', JSON.stringify(dataModal))
    return dataModal
}

export const setDataModalFromCatch = (e) => {
    console.error(e.message)
    const lang = objectLang().language
    return setErrorWindow(
        lang.modal.errors.title,
        '',
        lang.messages.code_999,
        '',
        'replaceTo /main',
        '',
        'replaceTo /main'
    )
}

export const refreshAT = async (auth, request) => {
    let host
    window.location.host === 'localhost:3000' ? host = config.localHost : host = config.heroku
    try {
        const data = await request(
            `${host}/api/user/refresh`,
            'GET',
            null,
            {
                Authorization: `Bearer ${auth.accessToken} RefreshToken ${auth.refreshToken} DeviceId ${auth.deviceId}`

            })
        if (data.status > 200) {
            auth.logout()
            window.location.replace('/main')
        } else {
            auth.login(data.rootFolder, data.avatarLink, data.accessToken, data.refreshToken, data.userId, data.nickName, data.language, data.deviceId)
        }
    } catch (e) {
        console.error(e.message)
    }
}

export const formatDate = (dateStr) => {
    if (isString(dateStr)) {
        const date = dateStr.split('T')[0]
        const arrDate = date.split('-')
        const year = arrDate[0]
        const month = arrDate[1]
        const day = arrDate[2]
        const timeWithSec = dateStr.split('T')[1].split('.')[0]
        const time = timeWithSec.split(':')[0] + ':' + timeWithSec.split(':')[1]
        return {date: day + '.' + month + '.' + year, time: time}
    }
    return ''
}

export const getDomain = (str) => {
    let res = 'default'
    const regexp = /^(?:[^:]+:\/\/)?([^./?#]+\.)*([^./?#]+)\.([^./?#]+)(?:$|[/?#])/
    if (str.match(regexp)) {
        res = str.match(regexp)[2]
    }
    return res
}

export const getLink = (str) => {
    let res = 'https://us-link.cloud'
    if (str) {
        if (str.includes("http")) {
            if (str.split("http").length >= 2) {
                res = `http${str.split("http")[1]}`
            }
        }
    }
    return res
}

export const getIcon = (linkFrom, defaultLink) => {
    const usLink_logo = require("./images/us-link-icon-500.png")
    const booking_logo = require("./images/booking-logo-2.png")
    const google_logo = require("./images/linkGoogle.png")
    const google_disk_logo = require("./images/google-disc-logo.png")
    const yandex_disk_logo = require("./images/yandex-disk.png")
    const docs_logo = require("./images/microsoft-word-logo.png")
    const classroom_logo = require("./images/google-classroom-logo.png")
    const airplane_logo = require("./images/airplane.jpg")
    const waze_logo = require("./images/waze-logo.png")
    const phone_logo = require("./images/phone-logo.jpg")
    const mail_logo = require("./images/mail-logo.png")
    const yandex_logo = require("./images/yandex-logo.png")
    const avito_logo = require("./images/avito-logo.png")
    const zoom_logo = require("./images/zoom-logo.png")
    const hapoalim_logo = require("./images/hapoalim-logo.png")
    const disney_logo = require("./images/disneyland-Logo.png")
    const gosuslugi_logo = require("./images/gosuslugi-logo.png")
    const fns_logo = require("./images/fns-logo.png")
    const aliexpress_logo = require("./images/aliexpress-logo.png")
    const meet_logo = require("./images/meet-logo.png")
    const youtube_logo = require("./images/youtube-logo.png")
    const netflix_logo = require("./images/netflix-logo.png")
    const ok_logo = require("./images/ok-logo.png")
    const vk_logo = require("./images/vk-logo.png")
    const facebook_logo = require("./images/facebook-logo.png")
    const video_logo = require("./images/video-logo.png")
    const acrobat_logo = require("./images/acrobat-logo.png")
    const google_map_logo = require("./images/google-map-logo.png")
    const google_map_icon_logo = require("./images/google-map-icon-logo.png")
    const instagram_logo = require("./images/instagram-logo.png")
    const paypal_logo = require("./images/paypal-logo.jpg")
    const super_pharm_logo = require("./images/supr-pharm-logo.png")
    const apartment_logo = require("./images/apartment-logo.jpg")
    const book_logo = require("./images/book-logo.png")
    const get_course_logo = require("./images/get-course-logo.png")
    const weezmo_logo = require("./images/weezmo-logo.jpg")
    const sberbank_logo = require("./images/sberbank-logo.png")
    const maccabi = require("./images/maccabi.png")
    const maccabi_dent = require("./images/maccabi-dent.png")
    const speedtest_logo = require("./images/speedtest-logo.png")
    const foods_logo = require("./images/foods-logo.png")
    const israel_post_logo = require("./images/doar-israel-logo.png")
    const image_file_logo = require("./images/image-icon.png")

    let domain = ''
    let split_HTTPS = linkFrom.split('https://')[1]
    let split_WWW = linkFrom.split('www.')[1]
    let split_slash = split_HTTPS
    if (split_HTTPS) {
        if (split_HTTPS.includes('/')) {
            split_slash = split_HTTPS.split('/')[0]
        } else {
            split_slash = split_HTTPS
        }
        domain = getDomain(split_slash)
    } else if (split_WWW) {
        if (split_WWW.includes('/')) {
            split_slash = split_WWW.split('/')[0]
        } else {
            split_slash = split_WWW
        }
        domain = getDomain(split_slash)
    } else {
        domain = 'default'
    }
    if (domain === 'co') {
        let il = linkFrom.split('co.')[1]
        let il_slash
        if (il) {
            il_slash = il.split('/')[0]
        } else {
            domain = 'default'
        }
        if (il_slash === 'il') {
            domain = getDomain(linkFrom.split('.il')[0])
        } else {
            domain = 'default'
        }
    }

    if (domain === 'google') {
        let drive = linkFrom.split('google')[0]
        if (drive.includes('drive')) {
            domain = 'drive'
        } else if (drive.includes('docs')) {
            domain = 'docs'
        } else {
            domain = 'google'
        }
    }

    if (domain === 'yandex') {
        let disk = linkFrom.split('yandex')[0]
        if (disk.includes('disk')) {
            domain = 'disk'
        } else {
            domain = 'yandex'
        }
    }

    if (domain === 'cloudinary') {
        let after = linkFrom.split('cloudinary')[0]
        if (after.includes('res')) {
            domain = 'cloudinary'
        } else {
            domain = 'default'
        }
    }

    switch (domain) {
        case 'us-link':
            return usLink_logo
        case 'google':
            return google_logo
        case 'passwords':
            return google_logo
        case 'drive':
            return google_disk_logo
        case 'disk':
            return yandex_disk_logo
        case 'yandex':
            return yandex_logo
        case 'docs':
            return docs_logo
        case 'classroom':
            return classroom_logo
        case 'wizzair':
            return airplane_logo
        case 'easyjet':
            return airplane_logo
        case 'elal':
            return airplane_logo
        case 'elal-mail':
            return airplane_logo
        case 'matmid':
            return airplane_logo
        case 'aeroflot':
            return airplane_logo
        case 's7':
            return airplane_logo
        case 'uralairlines':
            return airplane_logo
        case 'egyptair':
            return airplane_logo
        case 'aa':
            return airplane_logo
        case 'turkishairlines':
            return airplane_logo
        case 'pobeda':
            return airplane_logo
        case 'azimuth':
            return airplane_logo
        case 'waze':
            return waze_logo
        case 'booking':
            return booking_logo
        case 'golantelecom':
            return phone_logo
        case 'tele2':
            return phone_logo
        case 'mail':
            return mail_logo
        case 'avito':
            return avito_logo
        case 'edu-il':
            return zoom_logo
        case 'zoom':
            return zoom_logo
        case 'us04web':
            return zoom_logo
        case 'bankhapoalim':
            return hapoalim_logo
        case 'disneylandparis':
            return disney_logo
        case 'disneyland':
            return disney_logo
        case 'gosuslugi':
            return gosuslugi_logo
        case 'nalog':
            return fns_logo
        case 'aliexpress':
            return aliexpress_logo
        case 'meet':
            return meet_logo
        case 'youtube':
            return youtube_logo
        case 'youtu':
            return youtube_logo
        case 'netflix':
            return netflix_logo
        case 'ok':
            return ok_logo
        case 'vk':
            return vk_logo
        case 'facebook':
            return facebook_logo
        case 'kinovasek':
            return video_logo
        case 'rezka':
            return video_logo
        case 'zagonkop.db':
            return video_logo
        case 'anwap':
            return video_logo
        case 'kinonadzor':
            return video_logo
        case 'kinozapas':
            return video_logo
        case 'kinogo':
            return video_logo
        case 'filmix':
            return video_logo
        case 'adobe':
            return acrobat_logo
        case 'goo':
            return google_map_logo
        case 'maps':
            return google_map_icon_logo
        case 'instagram':
            return instagram_logo
        case 'paypal':
            return paypal_logo
        case 'super-pharm':
            return super_pharm_logo
        case 'myresidhome':
            return apartment_logo
        case 'isradon':
            return book_logo
        case 'alfavit':
            return book_logo
        case 'siman-kria':
            return book_logo
        case 'ok-mozg':
            return get_course_logo
        case 'volkovaforwomen':
            return get_course_logo
        case 'weezmo':
            return weezmo_logo
        case 'sberbank':
            return sberbank_logo
        case 'maccabi4u':
            return maccabi
        case 'maccabi-dent':
            return maccabi_dent
        case 'speedtest':
            return speedtest_logo
        case 'eda':
            return foods_logo
        case 'gotovim-doma':
            return foods_logo
        case 'yasensvit':
            return foods_logo
        case 'koolinar':
            return foods_logo
        case 'delo-vcusa':
            return foods_logo
        case 'vkusnyblog':
            return foods_logo
        case '1000':
            return foods_logo
        case 'baking-academy':
            return foods_logo
        case 'israelpost':
            return israel_post_logo
        case 'cloudinary':
            return image_file_logo
        default:
            return defaultLink
    }
}

export const getLinkSmall = (link) => {
    let split_HTTPS, result
    if (link.includes('https://')) {
        split_HTTPS = link.split('https://')[1]
    } else if (link.includes('http://')) {
        split_HTTPS = link.split('http://')[1]
    } else {
        split_HTTPS = link
    }

    if (split_HTTPS.includes('www.')) {
        result = split_HTTPS.split('www.')[1]
    } else {
        result = split_HTTPS
    }

    if (result) {
        return result
    } else {
        return link
    }
}

export const setLanguage = (accessToken, language) => {
    const languages = objectLang().languages
    const lang = objectLang().language
    let localStorageLang = lang.lang

    if (accessToken) {
        if (!localStorage.getItem('isChangedLang')) {
            if (language !== localStorageLang) {
                for (const i in languages) {
                    if (i === language) {
                        localStorage.setItem('language', JSON.stringify(languages[i]))
                    }
                }
                localStorage.setItem('isChangedLang', 'true')
                window.location.reload()
            }
        }
    }
}

export const objectLang = () => { // Return object {languages, language}
    let languages, language
    if (localStorage.getItem('languages')) {
        languages = JSON.parse(localStorage.getItem('languages'))
    } else {
        languages = {
            en: {
                lang: 'en',
                navbar: {
                    isAuth: {
                        title: '',
                        btnFolders: 'My Documents',
                        linkFolders: '/my-documents',
                        btnMyLinks: 'My Links',
                        linkMyLinks: '/my-links',
                        btnPublicLinks: 'Public Links',
                        linkPublicLinks: `/folder/open-public/${config.trialRootFolderId}`,
                        btnProfile: 'Profile',
                        linkProfile: '/profile',
                        placeholderSearch: 'Search...',
                        btnSearch: 'Search',
                        linkSearch: '/search',
                        btnOut: 'Exit',
                        linkOut: '/exit',
                    },
                    isNotAuth: {
                        title: 'Us-Link',
                        btnAuth: 'Login',
                        linkAuth: '/auth',
                        btnRegister: 'Register',
                        linkRegister: '/register',
                        placeholderSearch: 'Search...',
                        btnSearch: 'Search',
                        linkSearch: '/search',
                        btnPublicLinks: 'Public Links',
                        linkPublicLinks: `/folder/open-public/${config.trialRootFolderId}`,
                    },
                    toHome: "Main page",
                    linkToHome: '/main',
                    optionalsLanguage: {ru: 'Russian', en: 'English', he: 'Hebrew', de: 'German', ar: 'Arabic'},
                },
                body: {
                    welcome: 'Hello good guest of this site!\n' +
                        'The site is designed to save Internet links.\n' +
                        'In order not to remember for example:\n' +
                        '“How did I find that online store where I bought the thing I needed a month ago?”\n' +
                        'As a result, I now use this myself and my saved links already number about a thousand!\n' +
                        'I invite you to register and try it, maybe you will like it too.\n' +
                        'Please send all possible identified problems to the address: "using.links.mail@gmail.com".\n' +
                        'I will be glad to see you among the users of this site!',
                    general: {
                        btnBack: 'Back',
                        btnToHomePage: 'To Home Page',
                    },
                    contextMenu: {
                        auth: 'Login',
                        moveToFolder: 'Cut',
                        copyToFolder: 'Copy',
                        updateItem: 'Change',
                        openItem: 'Open',
                        createNewFolder: 'Create\u00A0a\u00A0new\u00A0folder',
                        createNewLink: 'Create\u00A0a\u00A0new\u00A0link',
                        createNewNote: 'Create\u00A0a\u00A0new\u00A0note',
                        propertiesItem: 'Properties',
                        deleteItem: 'Delete',
                        pasteFolder: 'Paste\u00A0folder',
                        pasteLink: 'Paste\u00A0link',
                        pasteNote: 'Paste\u00A0note',
                        clearBuffer: 'Clear\u00A0buffer',
                        uploadFile: 'upload\u00A0new\u00A0file'
                    },
                    authPage: {
                        title: 'Authentication ',
                        labelEmail: 'Email ',
                        placeholderEmail: 'Tap email',
                        labelPassword: 'Password ',
                        placeholderPassword: 'Tap password ',
                        btnLogin: 'Sign in',
                        btnRemindPass: 'Restore password',
                        codeVerify: 'Verification code',
                        tapeCode: 'Enter code from email',
                        getCodeAgain: 'Get code again',
                        inToAnotherAccount: 'Sign in to another account',
                        restorePassword: 'Restore password'
                    },
                    restorePage: {
                        title: 'Restore access',
                        labelEmail: 'Email ',
                        placeholderEmail: 'Tap Email',
                        btnSend: 'Send',
                    },
                    changePasswordPage: {
                        title: 'Enter your new password here',
                        labelPassword: 'Password',
                        placeholderPassword: 'Tap password',
                        btnSend: 'Send',
                    },
                    registerPage: {
                        title: 'Register',
                        labelEmail: 'Email (required)',
                        placeholderEmail: 'Tap email',
                        labelPhone: 'Phone (required)',
                        placeholderPhone: 'Tap phone',
                        labelFirstName: 'First name (required)',
                        placeholderFirstName: 'Tap first name',
                        labelLastName: 'Last name',
                        placeholderLastName: 'Tap last name',
                        labelNickName: 'Nick (required)',
                        placeholderNickName: 'Tap nick',
                        labelLanguage: 'Default language',
                        optionalsLanguage: {ru: 'Russian', en: 'English', he: 'Hebrew', de: 'German', ar: 'Arabic'},
                        labelCountry: 'Counter',
                        optionalsCountry: {russia: 'Russia', usa: 'USA', israel: 'Israel', germany: 'Germany'},
                        labelPasswordOne: 'Password (required)',
                        placeholderPasswordOne: 'Create a password',
                        labelPasswordTwo: 'Repeat password (required)',
                        placeholderPasswordTwo: 'Repeat password',
                        labelCheckBox: 'Send SMS on login',
                        btnRegister: 'Create Account',
                        btnRegisterGoogle: 'Sign up via Google',
                        labelCodeVerify: 'Verification code (required)',
                        placeholderCodeVerify: 'Tap verification code',
                        sendCodeVerify: 'Send code',
                    },
                    verifyPade: {
                        title: 'Verification',
                        description: 'Check your email, an email with a verification code has been sent to you. It must be entered in this field to complete the registration process.',
                        label: 'Verification code',
                        codePlaceholder: 'Enter verification code from email',
                        btnSendCode: 'Send',
                        btnBack: 'Back'
                    },
                    sharedPage: {
                        title: 'Shared access',
                    },
                    profilePage: {
                        title: 'My profile',
                        firstName: 'First name',
                        firstNameLabel: 'First name',
                        firstNamePlaceholder: 'First name',
                        lastName: 'Last name',
                        lastNameLabel: 'Last name',
                        lastNamePlaceholder: 'Last name',
                        nickName: 'Nick',
                        nickNameLabel: 'Nick',
                        nickNamePlaceholder: 'Nick',
                        email: 'Email',
                        emailLabel: 'Email',
                        emailPlaceholder: 'Email',
                        passwordOld: 'Password old',
                        passwordOldLabel: 'Password old',
                        passwordOldPlaceholder: 'Password old',
                        passwordNew: 'Password new',
                        passwordNewLabel: 'Password new',
                        passwordNewPlaceholder: 'Password new',
                        passwordRepeat: 'Repeat password',
                        passwordRepeatLabel: 'Repeat password',
                        passwordRepeatPlaceholder: 'Repeat password',
                        verify: 'Verify',
                        isActivated: 'Activated',
                        dateRegister: 'Date of registration',
                        phoneNumber: 'Phone',
                        phoneNumberLabel: 'Phone',
                        phoneNumberPlaceholder: 'Phone',
                        country: 'Country',
                        countryLabel: 'Country',
                        language: 'Language',
                        languageLabel: 'Language',
                        languagePlaceholder: 'Language',
                        avatar: 'Avatar',
                        about: 'About me',
                        aboutLabel: 'About me',
                        aboutPlaceholder: 'About me',
                        authWithSms: 'Send SMS at login',
                        updateProfile: 'Update profile',
                        cancelUpdateProfile: 'Cancel changes',
                        deleteAccountBtn: 'Delete account',
                        saveProfileBtn: 'Save',
                    },
                    linksPage: {
                        title: 'My links',
                        buttonDetail: 'Details',
                        btnCreateLink: 'New link',
                    },
                    linkPage: {
                        owner: 'Link owner',
                        createdDate: 'Created date:',
                        lastEntrance: 'Last entrance:',
                        numberOfClicks: 'Number of clicks:',
                        labelSiteLink: 'Site Link',
                        labelSiteTitle: 'Name of the site',
                        labelSiteDescription: 'Description of the site',
                        labelLoginSite: 'Login of the site',
                        labelPasswordSite: 'Password of the site',
                        placeholderSiteLink: 'Enter site link',
                        placeholderSiteTitle: 'Enter name of the site',
                        placeholderSiteDescription: 'Enter description of the site',
                        placeholderLoginSite: 'Enter login of the site',
                        placeholderPasswordSite: 'Enter password of the site',
                        buttonBack: 'Back',
                        buttonSave: 'Save',
                        buttonUpdate: 'Update',
                        buttonDelete: 'Delete',
                        isShare: 'Share link',
                        accessLink: 'Public link',
                    },
                    folderPage: {
                        owner: 'Folder owner',
                        createdDate: 'Created date:',
                        lastEntrance: 'Last entrance:',
                        labelName: 'Name',
                        placeholderName: 'Enter a name for the folder',
                        labelDescription: 'Description',
                        placeholderDescription: 'Enter a description for the folder',
                        buttonBack: 'Back',
                        buttonSave: 'Save',
                        buttonUpdate: 'Update',
                        buttonDelete: 'Delete',
                        isShare: 'Share link',
                        accessLink: 'Public link',
                    },
                    notePage: {
                        title: 'Note',
                        createdDate: 'Created date:',
                        lastEntrance: 'Last entrance:',
                        numberOfClicks: 'Number of clicks:',
                        labelNoteTitle: 'Name of note',
                        placeholderNoteTitle: 'Enter note name',
                        labelNoteBody: 'Text of note',
                        placeholderNoteBody: 'Enter note text',
                        buttonBack: 'Back',
                        buttonSave: 'Save',
                        buttonOpen: 'Open',
                        buttonDelete: 'Delete',
                        isShare: 'Share link',
                        accessLink: 'Public link',
                    },
                    createFolderPage: {
                        title: 'Create new folder',
                        placeholderName: 'Enter a name for the folder',
                        btnCreateFolder: 'Create folder',
                    },
                    createLinkPage: {
                        title: 'Create new link',
                        placeholderName: 'Enter a name for the link',
                        placeholderDesc: 'Enter a site description',
                        placeholderLink: 'Enter site link',
                        btnCreateLink: 'Create link',
                    },
                    createNotePage: {
                        title: 'Create new note',
                        placeholderName: 'Type a name for the note',
                        placeholderText: 'Type your note text',
                        btnCreateNote: 'Create note',
                        btnSaveNote: 'Save',
                        btnPropertiesNote: 'Properties',
                    },
                    myLinksPage: {
                        title: 'My Links',
                        titlePublicLinks: 'Public Links',
                    },
                    foldersPage: {
                        title: 'My folders',
                        btnOpenFolder: 'Open',
                        btnCreateFolder: 'New folder',
                        upToLevel: 'Up a level',
                        filterName: 'Filter',
                        filterPlaceholder: 'Filter',
                    },
                    notesPage: {
                        title: 'My notes',
                        btnCreateNote: 'New note',
                    },
                    sortRow: {
                        sortClicks: 'Clicks',
                        sortCount: 'Transitions',
                        sortName: 'Name',
                    },
                    updateLinkPage: {},
                    updateProfilePage: {},
                    errorPage: {
                        title: 'Произошла ошибка',
                        description: 'Здесь будет описание ошибки',
                        btnBack: 'На главную',
                    },
                    uploadFilePage: {
                        title: 'Загрузка файла',
                        placeholderName: 'Введите имя файла',
                        labelTextOne: 'Выберите файл',
                        labelTextMultiple: 'Выберите файлы',
                        inputTextOne: 'Выбран файл:',
                        inputTextMultiple: 'Выбрано файлов:',
                        buttonBack: 'Назад',
                        buttonSend: 'Отправить',
                        buttonCancel: 'Отмена',
                    },
                },
                footer: {
                    contacts: 'Contacts'
                },
                modal: {
                    titles: {
                        excellent: 'Excellent!'
                    },
                    errors: {
                        title: 'An error has occurred!',
                        deleteTrialProfile: 'This account cannot be deleted!',
                        desc: {
                            isLife: 'The token has expired. You need to log in to the system again.',
                            copyError: 'An error occurred while copying. Try to repeat the operation.'
                        },
                        btnCancel: 'Cancel',
                        btnOK: 'OK',
                        btnAuth: 'Authorization',
                        btnRestore: 'Restore access',
                        btnAgain: 'Login again',
                        btnTryAgain: 'Try again'
                    },
                    warnings: {
                        title: 'Attention!',
                        desc: {
                            deleteLink: 'When deleted, all data of this link will be lost without the possibility of recovery! Do you really want it?',
                            deleteProfile: 'When you delete your account, all your saved data will be lost without the possibility of recovery. Do you really want it?',
                            deleteNote: 'When deleted, all data of this note will be lost without the possibility of recovery! Do you really want it?',
                            deleteFolder: 'When deleting, all the contents of this folder will be lost without the possibility of recovery! Do you really want it?',
                        },
                        btnCancel: 'Cancel',
                        btnOK: 'Delete'
                    },
                    buttons: {
                        btnToOK: 'OK',
                        btnToMain: 'To Main',
                        btnToAuth: 'Authorization',
                        btnToRegister: 'Registration',
                        btnToBack: 'Back',
                        btnToCancel: 'Cancel'
                    }
                },
                messages: {
                    errorConstruction: 'This page is currently under development. We apologize for the inconvenience.',
                    fillToForm: 'Fill in all required form fields',
                    code_504: 'User is not found! Or you are not authorized! Please log in.',
                    code_540: 'You are not authorized! Please authorize.',
                    code_541: 'Access to content is prohibited!',
                    code_550: 'The password must be written in English letters and contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, have a length of 6 to 20 characters.',
                    code_551: 'Passwords are not equal.',
                    code_552: 'Incorrect password',
                    code_553: 'Enter the current password',
                    code_554: 'You have successfully changed your password. Now you need to log in again.',
                    code_555: 'Email is misspelled. Check correctness and try again',
                    code_556: 'Not a valid token! Continuation of work is impossible. Try again later',

                    code_600: 'Bad request',
                    code_601: 'Failed to login. Check the correctness of the entered data.',
                    code_6014: 'User is not found. Check that the email you entered is correct.',
                    code_6015: 'User is not found. The link is outdated. You need to request a new link to change your access password.',
                    code_602: 'User already verified',
                    code_603: 'Invalid verification code',
                    code_604: 'Failed to login. Possibly incorrect data entered',
                    code_6041: 'Access token expired',
                    code_6042: 'You need to log in again because you haven\'t been here for a long time!',
                    code_605: 'Email or password was not validated on the server',
                    code_606: 'User with this Email already registered. To log in, use the "Authorization" button',
                    code_607: 'Something went wrong. User not created in database',
                    code_608: 'Failed to send email',
                    code_609: 'Check email. We have sent you a confirmation code',
                    code_610: 'Failed to create user. You may have entered an invalid email',
                    code_611: 'Email not found',
                    code_612: 'A verification code has been sent to your phone number',
                    code_613: 'Your account has not yet been activated. To activate your account, click "OK" or follow the link from the received letter. In the window that opens, enter the code from the letter and click "Send".',
                    code_614: 'We have sent you a link by email. Use it to change the password for accessing the service.',
                    code_615: 'The password has been changed, now you need to log in with the new password!',

                    code_620: 'Bad request',
                    code_6204: 'Oops...! The folder has been moved or deleted!',
                    code_621: 'A folder with the same name already exists, please specify a different name or use an existing folder',
                    code_622: 'Folder created and saved',
                    code_623: 'Folder with this name not found',
                    code_624: 'Folder deleted!',
                    code_625: 'A folder with the same name already exists! Try a different name',
                    code_626: 'Folder not created! You can\'t paste a folder into itself! Try changing the insertion point',
                    code_627: 'Sorry, but you are not allowed to manage the properties of this folder.',
                    code_628: 'The public link has been created.',

                    code_630: 'Link created and saved in the database!',
                    code_6304: 'Oops...! The link has been moved or deleted!',
                    code_6305: 'Oops...! The Note has been moved or deleted!',
                    code_631: 'A link with the same name already exists in this folder! Try a different name',
                    code_632: 'Such a link already exists in this folder! You cannot create two links to the same place',
                    code_633: 'A folder with the same name already exists in this folder! Set a different name',
                    code_634: 'A note with the same name already exists in this folder! Try a different name',
                    code_635: 'The note has been created and saved in the database!',

                    code_700: 'Oops... Nothing found. Try changing your search criteria.',
                    code_701: 'Search results will appear here.',
                    code_702: 'Search results:',
                    code_703: 'Found',
                    code_704: 'Folders: ',
                    code_705: 'Links: ',
                    code_706: 'Notes: ',

                    code_655: 'This note name already exists in this directory. The name has not been changed, but the text of the note has been saved!',

                    code_999: 'Internal server error (500)! Something went wrong, please try again later'
                }
            }
        }
    }
    if (localStorage.getItem('language')) {
        language = JSON.parse(localStorage.getItem('language'))
    } else {
        language = languages.en
    }
    return {languages, language}
}

export const getQueryParams = () => {
    const res = {}
    for (const [key, value] of new URLSearchParams(window.location.search)) {
        res[key] = value
    }
    return res
}

export const deleteCookies = () => {
    localStorage.clear()
}

export const validationPassword = (passOne, passTwo, isRegister = false) => {
    const regexp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/
    let result = {res: '', code: '', flag: false}
    if (!passOne.match(regexp)) {
        result.code = 550
    } else if (!isRegister) { // Если нет второго пароля, значит вызываем функцию из авторизации
        result.res = passOne
        result.flag = true
    } else if (passOne !== passTwo) {
        result.code = 551
    } else {
        result.res = passOne
        result.flag = true
    }
    return result
}

export const validationEmail = (str) => {
    const regexp = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
    let result = {res: '', code: '', flag: false}
    if (!str.match(regexp)) {
        result.code = 555
    } else {
        result.res = str
        result.flag = true
    }
    return result
}

function isString(x) {
    return Object.prototype.toString.call(x) === '[object String]'
}

export const reformatDate = (date, sep = '.') => {
    if (isString(date)) {
        date = date.split('T')[0]
        const split = date.split('-')
        return split[2] + sep + split[1] + sep + split[0]
    }
    return ''
}

export const set_select_by_value = (select_id, option_val) => {
    $(`#${select_id} option[value='${option_val}']`).prop('selected', true)

}

export const eyeHandler = (jqueryEye, jqueryPassword) => {
    if (jqueryEye.hasClass('fa-eye-slash')) {
        jqueryEye.removeClass('fa-eye-slash').addClass('fa-eye')
        jqueryPassword.attr('type', 'text')
    } else {
        jqueryEye.removeClass('fa-eye').addClass('fa-eye-slash')
        jqueryPassword.attr('type', 'password')
    }
}

export const changeHandler = (event) => {
    const from = event.target
    const btnSave = $('#btn-save')
    const value = event.target.value
    if (btnSave.hasClass('disabled')) {
        btnSave.removeClass('disabled')
    }
    from.style.color = 'red'
    !value ? from.classList.remove('form-detail') : from.classList.add('form-detail')
}

export const clickUpdateHandler = () => {
    const lang = JSON.parse(localStorage.getItem('language'))

    const update = () => {
        $('#first-name-profile').attr('disabled', false)
        $('#last-name-profile').attr('disabled', false)
        $('#nick-name-profile').attr('disabled', false)
        $('#phone-number-profile').attr('disabled', false)
        $('#language-profile').attr('disabled', false)
        $('#about-me-profile').attr('disabled', false)
        $('#password-old-profile').attr('disabled', false)
        $('#password-new-profile').attr('disabled', false)
        $('#password-repeat-profile').attr('disabled', false)
        $('#link-update').html(lang.body.profilePage.cancelUpdateProfile)
        $('#btn-update').removeClass('disabled')
    }

    const cancelUpdate = () => {
        $('#first-name-profile').attr('disabled', true)
        $('#last-name-profile').attr('disabled', true)
        $('#nick-name-profile').attr('disabled', true)
        $('#phone-number-profile').attr('disabled', true)
        $('#language-profile').attr('disabled', true)
        $('#about-me-profile').attr('disabled', true)
        $('#password-old-profile').attr('disabled', true).val('')
        $('#password-new-profile').attr('disabled', true).val('')
        $('#password-repeat-profile').attr('disabled', true).val('')
        $('#link-update').html(lang.body.profilePage.updateProfile)
        $('#btn-update').addClass('disabled')
    }

    const divPass = $('#divPasswords')
    if (divPass.hasClass('d-none')) {
        divPass.removeClass('d-none')
        update()
    } else {
        divPass.addClass('d-none')
        cancelUpdate()
    }
}

export const contextMenuItem = (e, key) => {
    const contextMenuItemArr = document.querySelectorAll(".context-menu-item")
    contextMenuItemArr.forEach((element) => {
        element.classList.add('d-none')
    })
    if (e.target.id === "img-folder" || e.target.id === "img-link" || e.target.id === "img-note" || e.target.id === "img-context-menu" ||
        e.target.id === "name-folder" || e.target.id === "link-from" || e.target.id === "p-link-from" || e.target.id === "name-note") {
        contextMenuOff(e)
        e.preventDefault()
        let contextMenuItem = document.querySelector(`#context-menu-item-${key}`)
        positionMenu(contextMenuItem, e, false)
    } else {
        contextMenuOff(e)
    }
}

export const contextMenuCreateItem = (e) => {
    if (e.target.id === "row-items" || e.target.id === "main-container" || e.target.id === "body-items") {
        contextMenuOff(e)
        e.preventDefault()
        const contextMenuCreate = document.querySelector(`#context-menu-create`)
        positionMenu(contextMenuCreate, e, true)
        let isCopyFolder = !!localStorage.getItem('copyFolder'),
            isCopyLink = !!localStorage.getItem('copyLink'),
            isCopyNote = !!localStorage.getItem('copyNote')
        if (isCopyFolder || isCopyLink || isCopyNote) {
            document.querySelector('#context-menu-clear-buffer').classList.remove('d-none')
        } else {
            document.querySelector('#context-menu-clear-buffer').classList.add('d-none')
            document.querySelector('#context-menu-paste-folder').classList.add('d-none')
            document.querySelector('#context-menu-paste-link').classList.add('d-none')
            document.querySelector('#context-menu-paste-note').classList.add('d-none')
        }
    }
}

export const menuCreateItem = (e) => {
    contextMenuOff(e)
    e.preventDefault()
    const contextMenu = document.querySelector("#context-menu-create")
    positionMenu(contextMenu, e, true)
}

export const removeSearchParameter = () => {
    const history = createBrowserHistory()
    if (history.location.search) {
        history.push(history.location.pathname)
        window.location.reload()
    }
}

export const showAll = () => {
    const arr = Array.from(document.getElementById('items').children)
    if (arr) {
        for (const i in arr) {
            arr[i].classList.remove("d-none")
        }
    }
}

export const filterHandler = (value, documents) => {

    value ? value = value.toLowerCase() : value = ''

    const ifElse = (arr, filter, name, subText) => {
        if (name && filter && subText) {
            if (name.includes(filter) || subText.includes(filter)) {
                arr.classList.remove("d-none")
            } else {
                arr.classList.add("d-none")
            }
        } else if (name && filter && !subText) {
            if (name.includes(filter)) {
                arr.classList.remove("d-none")
            } else {
                arr.classList.add("d-none")
            }
        }
    }

    const filtering = (type, filter, name, subText) => {
        const arr = Array.from(document.getElementById('items').children)
        for (const i in arr) {
            const arrIName = arr[i].childNodes[arr[i].childNodes.length - 1].textContent.toLowerCase()
            if (type === 'folder' && arrIName === name) {
                if (arr[i].classList[0] === 'folder') {
                    ifElse(arr[i], filter, name, subText)
                }
            }
            if (type === 'link' && arrIName === name) {
                if (arr[i].classList[0] === 'link') {
                    ifElse(arr[i], filter, name, subText)
                }
            }
            if (type === 'note' && arrIName === name) {
                if (arr[i].classList[0] === 'note') {
                    ifElse(arr[i], filter, name, subText)
                }
            }
        }
    }

    if (document.getElementById('items')) {
        const folders = documents.folders
        const links = documents.links
        const notes = documents.notes
        for (const i in folders) {
            let name, description
            folders[i].name ? name = folders[i].name.toLowerCase() : name = ''
            folders[i].description ? description = folders[i].description.toLowerCase() : description = ''
            filtering('folder', value, name, description)
        }
        for (const i in links) {
            let name, from
            links[i].name ? name = links[i].name.toLowerCase() : name = ''
            links[i].from ? from = links[i].from.toLowerCase() : from = ''
            filtering('link', value, name, from)
        }
        for (const i in notes) {
            let name, body
            notes[i].name ? name = notes[i].name.toLowerCase() : name = ''
            notes[i].body ? body = notes[i].body.toLowerCase() : body = ''
            filtering('note', value, name, body)
        }
    }
}

export const contextMenuOff = (e) => {
    if (e.target.id === 'row-items' || e.target.id === 'body-items' || e.target.id === 'title-page' ||
        e.target.id === 'items' || e.target.id === 'name-link' || e.target.id === 'name-note' ||
        e.target.id === 'name-folders' || e.target.id === 'main-field' ||
        e.target.id.includes('menu-copy-item') || e.target.id.includes('menu-move-item')) {

        const contextMenuItemArr = document.querySelectorAll(".context-menu-item")
        const contextMenuCreateArr = document.querySelectorAll(".context-menu-create")
        contextMenuItemArr.forEach((element) => {
            element.classList.add('d-none')
        })
        contextMenuCreateArr.forEach((element) => {
            element.classList.add('d-none')
        })
    }
}

export const createCookiesOfName = (name, data) => {
    localStorage.setItem(name, JSON.stringify(data))
}

export const clearBuffer = () => {
    localStorage.removeItem('copyFolder')
    localStorage.removeItem('cutFolder')
    localStorage.removeItem('copyLink')
    localStorage.removeItem('cutLink')
    localStorage.removeItem('copyNote')
    localStorage.removeItem('cutNote')
    document.querySelector('#context-menu-paste-folder').classList.add('d-none')
    document.querySelector('#context-menu-paste-link').classList.add('d-none')
    document.querySelector('#context-menu-paste-note').classList.add('d-none')
    document.querySelector('#context-menu-clear-buffer').classList.add('d-none')
}

export const deleteCookiesAllFolders = () => {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const dataModal = JSON.parse(localStorage.getItem('dataModal'))
    const languages = JSON.parse(localStorage.getItem('languages'))
    const language = JSON.parse(localStorage.getItem('language'))
    const sort = JSON.parse(localStorage.getItem('sort'))
    const isChangedLang = localStorage.getItem('isChangedLang')
    const page = localStorage.getItem('page')
    const selectLang = localStorage.getItem('selectLang')
    const pathBack = localStorage.getItem('pathBack')
    localStorage.clear()
    localStorage.setItem('userData', JSON.stringify(userData))
    localStorage.setItem('dataModal', JSON.stringify(dataModal))
    localStorage.setItem('languages', JSON.stringify(languages))
    localStorage.setItem('language', JSON.stringify(language))
    localStorage.setItem('sort', JSON.stringify(sort))
    localStorage.setItem('isChangedLang', isChangedLang)
    localStorage.setItem('page', page)
    localStorage.setItem('selectLang', selectLang)
    localStorage.setItem('pathBack', pathBack)
}

export const getItems = (documents) => {
    const result = []
    const folders = documents.folders
    const links = documents.links
    const notes = documents.notes

    folders.forEach((folder) => {
        result.push(folder)
    })
    links.forEach((link) => {
        result.push(link)
    })
    notes.forEach((note) => {
        result.push(note)
    })
    return result
}

export const positionMenu = (menu, e, isMenuItem) => {
    const windowInnerWidth = document.documentElement.clientWidth
    const windowInnerHeight = document.documentElement.clientHeight
    const copyFolder = localStorage.getItem('copyFolder')
    const copyLink = localStorage.getItem('copyLink')
    const copyNote = localStorage.getItem('copyNote')
    let addPixelsHeight = 16 + 121.5
    let addPixelsWidth = 100
    if (isMenuItem) {
        addPixelsWidth = 200
    }
    if (copyFolder) {
        addPixelsHeight += 40.5
    }
    if (copyLink) {
        addPixelsHeight += 40.5
    }
    if (copyNote) {
        addPixelsHeight += 40.5
    }

    const bottomDot = e.clientY + addPixelsHeight
    const rightDot = e.clientX + addPixelsWidth

    if (rightDot > windowInnerWidth) {
        if ((e.clientX - addPixelsWidth) < 0) {
            menu.style.left = `0px`
        } else {
            menu.style.left = `${e.clientX - addPixelsWidth}px`
        }
    } else {
        menu.style.left = `${e.clientX}px`
    }
    if (bottomDot > windowInnerHeight) {
        if ((e.clientY - 200) < 0) {
            menu.style.top = `0px`
        } else {
            menu.style.top = `${e.clientY - addPixelsHeight}px`
        }
    } else {
        menu.style.top = `${e.clientY}px`
    }
    menu.className = 'context-menu-item'
}

export const justifyContents = (items) => {
    let scrollWidth = document.documentElement.scrollWidth
    let body_items_justify = 'justify-content-center'
    let row_items_justify = 'justify-content-center'
    let row_justify = 'justify-content-center'
    let length = 0
    if (items) {
        length = items.length
        if (scrollWidth >= 992) { // lg
            if (length < 9) {
                body_items_justify = 'justify-content-start'
            } else {
                body_items_justify = 'justify-content-center'
            }
            row_justify = 'justify-content-start'
        } else if (scrollWidth < 992 && scrollWidth >= 768) { // md
            body_items_justify = 'justify-content-center'
            row_items_justify = 'justify-content-center'
            row_justify = 'justify-content-center'
        } else if (scrollWidth < 768) { // xs
            body_items_justify = 'justify-content-center'
            row_items_justify = 'justify-content-center'
            row_justify = 'justify-content-between'
        }
        if (length <= 9) {
            scrollWidth = length * 136
        } else {
            scrollWidth = 9 * 136
        }
        if (length >= 9) {
            row_items_justify = 'justify-content-center'
        }
    }
    return {
        scrollWidth,
        body_items_justify,
        row_justify,
        row_items_justify
    }
}

export const getImagePath = (documents, user) => {
    let res = `U_${user.userId}/FILES`
    if (documents.upLevel === 'root') {
        res = `U_${user.userId}/FILES/F_${user.rootFolder}`
    }
    const arrPath = documents.path
    for (const i in arrPath) {
        const folderId = arrPath[i][1]
        if (folderId === '0') {
            continue
        }
        res += `/F_${folderId}`
    }

    let now = new Date()

    const nameFile = `image_${dateFormat(now)}`
    return `${res}/${nameFile.replace(/[\s.,;:]/g, '')}`
}

export const getAvatarImagePath = (user) => {
    let res = `U_${user.userId}/AVATARS`
    let now = new Date()
    const nameFile = `image_${dateFormat(now)}`
    return `${res}/${nameFile.replace(/[\s.,;:]/g, '')}`
}

export const dateFormat = (date) => {
    // format = 'dd.mm.year_hour:mm:ss_ms'
    let d = new Date(date)
    // console.log(d.getMonth() + 1)
    let zero
    d.getMonth() + 1 <= 9 ? zero = '0' : zero = ''
    return `${d.getDate()}.${zero}${d.getMonth() + 1}.${d.getFullYear()}_${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}_${d.getMilliseconds()}`
}

export const setCheckIconLink = async (htmlId, from, flagCheckTrue, flagCheckFalse, flagCheckEmpty) => {
    setTimeout(() => {
        const icon = document.querySelector(`#${htmlId}`)
        if (icon) {
            if (!from.includes('https')) {
                icon.src = flagCheckFalse
            } else {
                try {
                    $.ajax({
                        url: from,
                        async: true,
                        complete: (res) => {
                            // console.log(res.statusText, res.status, res.body)
                            if (res.statusText === "success") {
                                icon.src = flagCheckTrue
                            } else if (res.statusText === "error") {
                                icon.src = flagCheckEmpty
                            }
                        }
                    })
                } catch (e) {
                    icon.src = flagCheckEmpty
                }
            }
        }
    }, 100)
}