import React from 'react'
import {CreateLinkPage} from './pages/CreateLinkPage'
import {LinkDetailPage} from './pages/LinkDetailPage'
import {AuthPage} from './pages/AuthPage'
import {DeletePage} from "./elements/DeletePage"
import {ProfilePage} from "./pages/ProfilePage"
import {RegisterPage} from "./pages/RegisterPage"
import {MainPage} from "./pages/MainPage"
import {Routes, Route, Navigate} from 'react-router-dom'
import {ExitPage} from "./elements/ExitPage"
import {NotePage} from "./pages/NotePage"
import {VerifyPage} from "./pages/VerifyPage"
import {CreateFolderPage} from "./pages/CreateFolderPage"
import {FolderPage} from "./pages/FolderPage"
import {RootLinksPage} from "./pages/RootLinksPage"
import {FolderDetailPage} from "./pages/FolderDetailPage"
import {CreateNotePage} from "./pages/CreateNotePage"
import {NoteDetailPage} from "./pages/NoteDetailPage"
import {ModalWin} from "./elements/ModalWin"
import {RestorePage} from "./pages/RestorePage"
import {ChangePasswordPage} from "./pages/ChangePasswordPage"
import {LoaderClass} from "./elements/LoaderClass"
import {UploadSingleImageFilePage} from "./pages/UploadSingleImageFilePage"
import {UploadAvatar} from "./elements/UploadAvatar"
import {UploadAvatarToPage} from "./elements/UploadAvatarToPage";

export const useRoutes = isAuthenticated => {
    if (isAuthenticated) {
        return (
            <Routes>
                <Route exact path="/loading" element={<LoaderClass />}/>
                <Route exact path="/modal" element={<ModalWin data={JSON.parse(localStorage.getItem('dataModal'))}/>}/>
                <Route exact path="/" element={<MainPage/>}/>
                <Route exact path="/main" element={<MainPage/>}/>
                <Route exact path="/my-links" element={<RootLinksPage/>}/>
                <Route exact path="/folder/open/undefined" element={<MainPage/>}/>}
                <Route exact path="/folder/open/:id" element={<FolderPage/>}/>}
                <Route exact path="/folder/open-public/:id" element={<FolderPage isPublic={true}/>}/>}
                <Route exact path="/note" element={<NotePage/>}/>
                <Route exact path="/create-link" element={<CreateLinkPage/>}/>
                <Route exact path="/create-folder" element={<CreateFolderPage/>}/>
                <Route exact path="/create-note" element={<CreateNotePage/>}/>
                <Route exact path="/note/open/:id" element={<NotePage/>}/>
                <Route exact path="/folder/open/undefined" element={<Navigate to="/main" replace/>}/>
                <Route exact path="/folder/open/0" element={<Navigate to="/my-links" replace/>}/>
                <Route exact path="/exit" element={<ExitPage/>}/>
                <Route exact path="/link/detail/:id" element={<LinkDetailPage/>}/>
                <Route exact path="/folder/detail/:id" element={<FolderDetailPage/>}/>
                <Route exact path="/note/detail/:id" element={<NoteDetailPage/>}/>
                <Route exact path="/link/remove/:id" element={<DeletePage string={'link'}/>}/>
                <Route exact path="/folder/remove/:id" element={<DeletePage string={'folder'}/>}/>
                <Route exact path="/note/remove/:id" element={<DeletePage string={'note'}/>}/>
                <Route exact path="/profile" element={<ProfilePage/>}/>
                <Route exact path="/auth" element={<Navigate to="/main" replace/>}/>
                <Route exact path="/register" element={<Navigate to="/profile" replace/>}/>
                <Route exact path="/upload-image-file" element={<UploadSingleImageFilePage/>}/>
                <Route exact path="/upload-avatar-image" element={<UploadSingleImageFilePage isAvatar={true}/>}/>
                <Route exact path="/upload-avatar-file" element={<UploadAvatarToPage/>}/>
                <Route exact path="/upload-avatar" element={<UploadAvatar/>}/>
            </Routes>
        )
    } else {
        return (
            <Routes>
                <Route exact path="/" element={<MainPage/>}/>
                <Route exact path="/loading" element={<LoaderClass />}/>
                <Route exact path="/verify" element={<VerifyPage/>}/>
                <Route exact path="/main" element={<MainPage/>}/>
                <Route exact path="/auth" element={<AuthPage/>}/>
                <Route exact path="/restore" element={<RestorePage/>}/>
                <Route exact path="/change-password" element={<ChangePasswordPage/>}/>
                <Route exact path="/register" element={<RegisterPage/>}/>
                <Route exact path="/note/open/:id" element={<NotePage/>}/>
                <Route exact path="/note/detail/:id" element={<ModalWin data={JSON.parse(localStorage.getItem('dataModal'))}/>}/>
                <Route exact path="/note/remove/:id" element={<ModalWin data={JSON.parse(localStorage.getItem('dataModal'))}/>}/>
                <Route exact path="/profile" element={<Navigate to="/main" replace/>}/>
                <Route exact path="/create-link" element={<Navigate to="/main" replace/>}/>
                <Route exact path="/links" element={<Navigate to="/main" replace/>}/>
                <Route exact path="/folders" element={<Navigate to="/main" replace/>}/>
                <Route exact path="/my-links" element={<Navigate to="/main" replace/>}/>
                <Route exact path="/notes" element={<Navigate to="/main" replace/>}/>
                <Route exact path="/link/detail/:id" element={<ModalWin data={JSON.parse(localStorage.getItem('dataModal'))}/>}/>
                <Route exact path="/link/remove/:id" element={<ModalWin data={JSON.parse(localStorage.getItem('dataModal'))}/>}/>
                <Route exact path="/exit" element={<MainPage/>}/>
                <Route exact path="/folder/open/:id" element={<MainPage/>}/>}
                <Route exact path="/folder/open-public/:id" element={<FolderPage isPublic={true}/>}/>}
                <Route exact path="/folder/detail/:id" element={<ModalWin data={JSON.parse(localStorage.getItem('dataModal'))}/>}/>
                <Route exact path="/folder/remove/:id" element={<ModalWin data={JSON.parse(localStorage.getItem('dataModal'))}/>}/>
                <Route exact path="/modal" element={<ModalWin data={JSON.parse(localStorage.getItem('dataModal'))}/>}/>
                <Route exact path="/upload-image-file" element={<UploadSingleImageFilePage/>}/>
            </Routes>
        )
    }
}
