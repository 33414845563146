import React from 'react'
import {Link} from "react-router-dom"
import {
    changeHandler,
    formatDate,
    objectLang,
} from "../functions"

export class NoteCardClass extends React.Component {

    render() {

        const {note, owner, removeNoteHandler} = {
            note: this.props.note,
            owner: this.props.owner,
            removeNoteHandler: this.props.removeNoteHandler
        }

        let lang = objectLang().language

        return (
            <>
                <div className={'container col col-sm-12 col-xl-6'}>
                    <form className={'rounded form-link-detail mt-2'}>
                        <div className={"mb-5 ms-2 me-1 mt-3 d-flex justify-content-between"}>
                            <div className={"d-flex justify-content-center w-100"}>
                                <Link to={`${`/note/open/${note["_id"]}`}`}
                                      className={"btn btn-success w-100 ms-2 me-1 fs-3 p-0"}>
                                    {note.name}
                                </Link>
                            </div>
                            <button id={"button-close-link"}
                                    type={"button"}
                                    className={"btn-close fs-2 p-2 m-0 me-1"}
                                    aria-label={"Close"}
                                    onClick={() => {
                                        window.location.replace(localStorage.getItem('pathBack'))
                                    }}>
                            </button>
                        </div>
                        <p
                            id={"p-date-created"}
                            className={"mt-3 ms-3 mb-1"}>
                            {`${lang.body.linkPage.createdDate}`}&nbsp;
                            <strong>{formatDate(note["dateCreated"]).date}</strong>
                        </p>

                        <fieldset className={'teal m-3 p-3'}>

                            <div id={"divFrom"} className={"mb-1"}>
                                <label
                                    id={"owner-label"}
                                    htmlFor={"owner"}
                                    className={"form-label"}>
                                    {"Владелец записки"}
                                </label>
                                <h4
                                    id={"owner"}
                                    className={`mb-3 ms-3`}
                                    placeholder={`${lang.body.notePage.placeholderSiteLink}`}>
                                    {`${owner.name}`}
                                </h4>
                            </div>

                            <div id={"divName"} className={"mb-3"}>
                                <label
                                    id={"label-name"}
                                    htmlFor={"name"}
                                    className={"form-label"}>
                                    {`${lang.body.notePage.labelNoteTitle}`}
                                </label>
                                <input
                                    disabled
                                    type={"text"}
                                    name={"name"}
                                    id={"name"}
                                    defaultValue={`${note.name}`}
                                    onChange={changeHandler}
                                    className={"form-control form-detail"}
                                    placeholder={`${lang.body.folderPage.placeholderName}`}
                                />
                            </div>
                            <div id={"divDescription"} className={"mb-3"}>
                                <label
                                    id={"label-description"}
                                    htmlFor={"description"}
                                    className={"form-label"}>
                                    {`${lang.body.notePage.labelNoteBody}`}
                                </label>
                                <textarea
                                    disabled
                                    name={"body"}
                                    id={"body"}
                                    defaultValue={note.body}
                                    onChange={changeHandler}
                                    className={"form-control form-detail"}
                                    placeholder={`${lang.body.notePage.placeholderNoteBody}`}
                                />
                            </div>
                            <div className={"ms-1 me-1 row row-cols-xl-5 gap-xl-2 justify-content-center"}>
                                <Link to={localStorage.getItem('pathBack')}
                                      id={"btn-back"}
                                      className={"form-detail-btn btn btn-info mt-2"}>
                                    {`${lang.body.notePage.buttonBack}`}
                                </Link>
                                <Link to={`/note/open/${note["_id"]}`}
                                      id={"btn-open"}
                                      className={"form-detail-btn btn btn-warning mt-2"}>
                                    {`${lang.body.notePage.buttonOpen}`}
                                </Link>
                                <Link to={``}
                                      id={"btn-delete"}
                                      className={"form-detail-btn btn btn-danger mt-2"}
                                      onClick={removeNoteHandler}>
                                    {`${lang.body.notePage.buttonDelete}`}
                                </Link>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </>
        )
    }
}
